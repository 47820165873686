import { useState, useEffect } from 'react';
import { EP, callApi } from '../api';

const useFetchStores = () => {
  const [stores, setStores] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    async function fetchData() {
      try {
        setIsLoading(true);
        const { results: stores } = await callApi(EP.stores.list);
        setStores(stores);
        setIsLoading(false);
      } catch (e) {
        console.log(e);
        setIsLoading(false);
        setIsError(true);
      }
    }
    fetchData();
  }, []);
  
  return { stores, isLoading, isError, setStores };
}

export default useFetchStores;