import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Auth } from 'aws-amplify';
import store from 'store';
import { navigate } from 'gatsby';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import TransitionAlert from '../components/transition-alert';
import useAuth from '../components/use-auth';
import useFetchStores from '../components/use-fetch-stores';
import Layout from '../components/layout';
import useFormInput from '../components/use-form-input';
import Loading from '../components/loading';
import useLoginStyles from '../components/use-login-styles';
import { USER_ROLES } from '../constants';

const getFilteredStores = (auth, stores) => {
  if (!auth) return [];
  if (auth.stores === 'ALL') return stores;
  try {
    const userStores = JSON.parse(auth.stores);
    return stores.filter(s => userStores.includes(s.id));
  } catch (e) {
    // TODO: show error
    return [];
  }
}

const getDefaultStore = (auth) => {
  if (!auth) return;
  try {
    const { storeId } = store.get('settings');
    if (!storeId) return;
    if (auth.stores === 'ALL') return storeId;
    const userStores = JSON.parse(auth.stores);
    return storeId !== undefined && userStores.includes(storeId)
      ? storeId
      : undefined;
  } catch (e) {
    return;
  }
}

function SelectStorePage({path}) {
  const { t } = useTranslation('login');
  const auth = useAuth();
  const classes = useLoginStyles();
  
  const storeId = useFormInput();
  const { stores, isLoading: isLoadingStores, isError: isErrorStores } = useFetchStores();
  useEffect(() => {
    const defaultStore = getDefaultStore(auth);
    storeId.setValue(defaultStore || '');
  }, [auth]);

  const filteredStores = getFilteredStores(auth, stores);
  if (!isLoadingStores && !filteredStores.length) {
    if (auth && [USER_ROLES.SYSTEM_ADMIN, USER_ROLES.TENANT_OWNER, USER_ROLES.TENANT_ADMIN].includes(auth.role)) {
      navigate('/stores');
    }
  }
  if (!isLoadingStores && filteredStores.length === 1) {
    store.set('settings', { ...store.get('settings'), storeId: filteredStores[0].id })
    navigate('/');
    return <Loading />;
  }
  const handleOnSubmit = async (e) => {
    e.preventDefault();
    store.set('settings', { ...store.get('settings'), storeId: storeId.value })
    navigate('/');
  }
  const handleLogout = async () =>{
    await Auth.signOut();
    navigate('/login');
  }


  if (isLoadingStores) return <Loading />;
  
  return (
    <Layout path={path}>
      <Container component="main" maxWidth="xs">
        <div className={classes.paper}>
          <Typography component="h1" variant="h5">
            {t('selectStore')}
          </Typography>
          { isLoadingStores && <Loading /> }
          { isErrorStores && (
            <TransitionAlert className={classes.alert} severity="error" isOpen>{t('errorFetchingStores')}</TransitionAlert>
          ) }
          { !filteredStores.length && <Typography className={classes.message}>{t('noStores')}</Typography> }
          { !isLoadingStores && !isErrorStores && !!filteredStores.length && (
            <form className={classes.form} noValidate>
              <FormControl variant="outlined" fullWidth className={classes.formControl}>
                <InputLabel id="store-label">{t('store')}</InputLabel>
                <Select
                  labelId="store-label"
                  id="store"
                  value={storeId.value}
                  onChange={storeId.onChange}
                  label={t('store')}
                >
                  { filteredStores.map( s => <MenuItem key={s.id} value={s.id}>{s.name}</MenuItem>) }
                </Select>
              </FormControl>
              <div></div>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={handleOnSubmit}
              >
                {t('selectStore')}
              </Button>
              <Button
                type="button"
                fullWidth
                variant="outlined"
                color="primary"
                className={classes.submit}
                onClick={handleLogout}
              >
                {t('logOut')}
              </Button>
            </form>
          )}
        </div>
      </Container>
    </Layout>
  );
}

export default SelectStorePage;
